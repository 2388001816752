import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { getListPageCardComponent } from '@src/containers/list/helpers';
import { PageHeader } from '@src/containers/list/PageHeader';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { getHasUtmLabel } from '@src/reducers/route';
import { isServer } from '@src/utils/isServer';
import { Layout as AppLayout } from 'app/Layout';
import { useIsPageWithCalculator } from 'features/Calculator';
import { CalculatorWithHeaders } from 'widgets/CalculatorWithHeaders';

import styles from './ListPage.module.scss';

const SeoComponents = dynamic(() => import('@src/components/SeoComponents'));

const pageConfig: IPageContextConfig = {
    listKey: ListKeys.LIST,
    source: 'search',
    sortPanelVisible: true,
    seoLinksVisible: true,
    extraFiltersVisible: true,
    cardTermIsVisible: true,
    withBanners: true,
    withInvitationBanner: true,
    withEmailBurner: true,
    withSelectionBanner: true,
    withCardProductLink: true,
    isMainPage: true,
};

export const ListPage: NextPage = () => {
    const isMobile = useIsMobile();
    const { filter, seo, pathname } = useListPage(ListKeys.LIST);
    const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);

    const { asPath } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');

    useLoadRestOffers(ListKeys.LIST);
    useBestProducts();

    const isCalculatorVisible = useIsPageWithCalculator();

    return (
        <AppLayout>
            <PageContext.Provider value={config}>
                <PageHeader className={styles.page_header} seo={seo} filter={filter} />
                <ProductList
                    className={styles.product_list}
                    filter={filter}
                    pathname={pathname}
                    renderCard={getListPageCardComponent(pathWithoutQuery)}
                />
                {isCalculatorVisible && <CalculatorWithHeaders filters={filter.filters} />}
                {seo && <SeoComponents seo={seo} breadcrumbs={seo.breadcrumbs} prefooter={seo.prefooter} />}
                <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
            </PageContext.Provider>
        </AppLayout>
    );
};

ListPage.getInitialProps = (ctx: Application.ReduxNextPageContext) => {
    if (isServer) return {};

    const hasUtmLabel = getHasUtmLabel(ctx.store.getState());

    return getPageListData(ctx, {
        key: ListKeys.LIST,
        defaultFiltersMode: hasUtmLabel ? MODES.AS_UTM : MODES.DEFAULT,
    });
};
