import type { CommonCardProps } from '@src/@types/commonCardProps';
import Card from '@src/components/ProductList/components/Card';
import { CardV2 } from '@src/components/ProductList/components/CardV2';
import { isNaKartuPage } from '@src/utils/routing';

export const getListPageCardComponent = (path: string) => (props: CommonCardProps) => {
    const isNewCardDesign = isNaKartuPage(path);
    const CardComponent = isNewCardDesign ? CardV2 : Card;

    return <CardComponent {...props} />;
};
